import React from "react";

export default function Footer() {
  return (
    <footer className="">
      <nav className="panel bottom forceMobileView">
        <div className="sections desktop">
          <div className="left"></div>
          <div className="right">
            <ul className="menu uppercase opacity-5">
              <li>
                <a href="https://dandeworld.com" target="_blank">&#169; 2023 The Unflux, a Dandeworld Project</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <nav className="panel bottom">
        <div className="sections desktop">
          <div className="left">
            <span className="opacity-5">Made with &#10084; from Africa</span>
          </div>
        </div>
      </nav>
    </footer>
  );
}
