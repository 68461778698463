import PropTypes from "prop-types";
import React from "react";

import Header from "./header";
import Footer from "./footer";

function Layout({ children, light = true }) {
  return (
    <div className="">
      <Header light={light} />
      {children}
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  light: PropTypes.bool,
};

export default Layout;
